/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddSignupRestrictionToCourseInstanceModel,
  AddUserToCourseInstanceModel,
  CourseInstanceModel,
  NotFoundExceptionModel,
  SignUpRestrictionModel,
  StatDataModel,
  UnauthorizedExceptionModel,
  UpdateCourseInstanceModel,
  UserCourseInstanceModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class CourseInstance<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CourseInstance
   * @name GetCourseInstanceById
   * @request GET:/api/CourseInstance/{courseInstanceId}
   * @secure
   */
  getCourseInstanceById = (
    courseInstanceId: string,
    params: RequestParams = {},
  ) =>
    this.request<CourseInstanceModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name UpdateCourseInstance
   * @request PUT:/api/CourseInstance/{courseInstanceId}
   * @secure
   */
  updateCourseInstance = (
    courseInstanceId: string,
    data: UpdateCourseInstanceModel,
    params: RequestParams = {},
  ) =>
    this.request<
      CourseInstanceModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/CourseInstance/${courseInstanceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name DeleteCourseInstance
   * @request DELETE:/api/CourseInstance/{courseInstanceId}
   * @secure
   */
  deleteCourseInstance = (
    courseInstanceId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name GetActiveCourseInstanceAndCourseById
   * @request GET:/api/CourseInstance/{courseInstanceId}/active
   * @secure
   */
  getActiveCourseInstanceAndCourseById = (
    courseInstanceId: string,
    params: RequestParams = {},
  ) =>
    this.request<CourseInstanceModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/active`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name GetParticipantsByCourseInstanceId
   * @request GET:/api/CourseInstance/{courseInstanceId}/participants
   * @secure
   */
  getParticipantsByCourseInstanceId = (
    courseInstanceId: string,
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/participants`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name AddNewParticipantToCourse
   * @request POST:/api/CourseInstance/{courseInstanceId}/participants
   * @secure
   */
  addNewParticipantToCourse = (
    courseInstanceId: string,
    data: AddUserToCourseInstanceModel,
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/participants`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name AssignExistingUserToCourse
   * @request PUT:/api/CourseInstance/{courseInstanceId}/participants
   * @secure
   */
  assignExistingUserToCourse = (
    courseInstanceId: string,
    query?: {
      /** @format uuid */
      userId?: string
      Optional1?: string
      Optional2?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/participants`,
      method: 'PUT',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name AddMeToCourseInstance
   * @request PUT:/api/CourseInstance/{courseInstanceId}/participants/assignme
   * @secure
   */
  addMeToCourseInstance = (
    courseInstanceId: string,
    query?: {
      Optional1?: string
      Optional2?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/participants/assignme`,
      method: 'PUT',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name GetSignupRestrictions
   * @request GET:/api/CourseInstance/{courseInstanceId}/signuprestrictions
   * @secure
   */
  getSignupRestrictions = (
    courseInstanceId: string,
    params: RequestParams = {},
  ) =>
    this.request<SignUpRestrictionModel[], NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/signuprestrictions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name AddSignupRestriction
   * @request POST:/api/CourseInstance/{courseInstanceId}/signuprestrictions
   * @secure
   */
  addSignupRestriction = (
    courseInstanceId: string,
    data: AddSignupRestrictionToCourseInstanceModel,
    params: RequestParams = {},
  ) =>
    this.request<SignUpRestrictionModel, NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/signuprestrictions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags CourseInstance
   * @name GetStats
   * @request GET:/api/CourseInstance/{courseInstanceId}/stats
   * @secure
   */
  getStats = (courseInstanceId: string, params: RequestParams = {}) =>
    this.request<StatDataModel[], NotFoundExceptionModel>({
      path: `/api/CourseInstance/${courseInstanceId}/stats`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
}
