/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddCourseInstanceToCourseModel,
  AddQuestionToCourseModel,
  CourseInstanceModel,
  CourseModel,
  ModuleModel,
  NotFoundExceptionModel,
  QuestionModel,
  ResourceModel,
  SectionModel,
  UnauthorizedExceptionModel,
  UpdateCourseModel,
  UpdateCourseModuleOrderModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Course<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Course
   * @name GetCourseById
   * @request GET:/api/Course/{courseId}
   * @secure
   */
  getCourseById = (courseId: string, params: RequestParams = {}) =>
    this.request<CourseModel, NotFoundExceptionModel>({
      path: `/api/Course/${courseId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name UpdateCourse
   * @request PUT:/api/Course/{courseId}
   * @secure
   */
  updateCourse = (
    courseId: string,
    data: UpdateCourseModel,
    params: RequestParams = {},
  ) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name DeleteCourse
   * @request DELETE:/api/Course/{courseId}
   * @secure
   */
  deleteCourse = (courseId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Course/${courseId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name UpdateCourseCompletionImage
   * @request PUT:/api/Course/{courseId}/images/coursecompletionimage
   * @secure
   */
  updateCourseCompletionImage = (
    courseId: string,
    data: {
      /** @format binary */
      courseCompletionImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/images/coursecompletionimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name DeleteCourseCompletionImage
   * @request DELETE:/api/Course/{courseId}/images/coursecompletionimage
   * @secure
   */
  deleteCourseCompletionImage = (
    courseId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/images/coursecompletionimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name UpdateCourseImage
   * @request PUT:/api/Course/{courseId}/images/courseimage
   * @secure
   */
  updateCourseImage = (
    courseId: string,
    data: {
      /** @format binary */
      courseImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/images/courseimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name DeleteCourseImage
   * @request DELETE:/api/Course/{courseId}/images/courseimage
   * @secure
   */
  deleteCourseImage = (courseId: string, params: RequestParams = {}) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/images/courseimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name GetCourseInstances
   * @request GET:/api/Course/{courseId}/instances
   * @secure
   */
  getCourseInstances = (courseId: string, params: RequestParams = {}) =>
    this.request<CourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/Course/${courseId}/instances`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name AddCourseInstanceToCourse
   * @request POST:/api/Course/{courseId}/instances
   * @secure
   */
  addCourseInstanceToCourse = (
    courseId: string,
    data: AddCourseInstanceToCourseModel,
    params: RequestParams = {},
  ) =>
    this.request<
      CourseInstanceModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/instances`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name GetModules
   * @request GET:/api/Course/{courseId}/modules
   * @secure
   */
  getModules = (courseId: string, params: RequestParams = {}) =>
    this.request<ModuleModel[], NotFoundExceptionModel>({
      path: `/api/Course/${courseId}/modules`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name AddNewModuleToCourse
   * @request POST:/api/Course/{courseId}/modules
   * @secure
   */
  addNewModuleToCourse = (
    courseId: string,
    data: {
      Name?: string
      Description?: string
      PreInfo?: string
      Tags?: string
      /** @format binary */
      ModuleImage?: File
      ModuleCompletionTitle?: string
      ModuleCompletionDescription?: string
      /** @format binary */
      ModuleCompletionImage?: File
      SendCompleteEmail?: boolean
      ModuleCompleteEmailOptional?: string
      ModuleMovement?: string
      ModuleProgression?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/modules`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name UpdateCourseModuleOrder
   * @request PUT:/api/Course/{courseId}/modules/order
   * @secure
   */
  updateCourseModuleOrder = (
    courseId: string,
    data: UpdateCourseModuleOrderModel,
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/modules/order`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name GetQuestions
   * @request GET:/api/Course/{courseId}/questions
   * @secure
   */
  getQuestions = (courseId: string, params: RequestParams = {}) =>
    this.request<QuestionModel[], NotFoundExceptionModel>({
      path: `/api/Course/${courseId}/questions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name AddQuestion
   * @request POST:/api/Course/{courseId}/questions
   * @secure
   */
  addQuestion = (
    courseId: string,
    data: AddQuestionToCourseModel,
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/questions`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name GetResources
   * @request GET:/api/Course/{courseId}/resources
   * @secure
   */
  getResources = (courseId: string, params: RequestParams = {}) =>
    this.request<ResourceModel[], NotFoundExceptionModel>({
      path: `/api/Course/${courseId}/resources`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Course
   * @name AddResource
   * @request POST:/api/Course/{courseId}/resources
   * @secure
   */
  addResource = (
    courseId: string,
    data: {
      Name?: string
      Description?: string
      Url?: string
      ResourceType?: string
      /** @format binary */
      File?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Course/${courseId}/resources`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
