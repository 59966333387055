/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotFoundExceptionModel,
  UpdateUserModel,
  UpdateUserPasswordModel,
  UserModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class User<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name GetUserByEmailAddress
   * @request GET:/api/User/{emailaddress}
   * @secure
   */
  getUserByEmailAddress = (emailaddress: string, params: RequestParams = {}) =>
    this.request<UserModel, NotFoundExceptionModel>({
      path: `/api/User/${emailaddress}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name GetUserById
   * @request GET:/api/User/{userid}
   * @secure
   */
  getUserById = (userid: string, params: RequestParams = {}) =>
    this.request<UserModel, NotFoundExceptionModel>({
      path: `/api/User/${userid}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name UpdateUser
   * @request PUT:/api/User/{userid}
   * @secure
   */
  updateUser = (
    userid: string,
    data: UpdateUserModel,
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/${userid}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name UpdateLockedState
   * @request PUT:/api/User/{userid}/lockedstate
   * @secure
   */
  updateLockedState = (
    userid: string,
    query?: {
      isLocked?: boolean
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/${userid}/lockedstate`,
      method: 'PUT',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name ChangePassword
   * @request PUT:/api/User/{userid}/changepassword
   * @secure
   */
  changePassword = (
    userid: string,
    data: UpdateUserPasswordModel,
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/${userid}/changepassword`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name UpdateProfileImage
   * @request PUT:/api/User/{userid}/profileimage
   * @secure
   */
  updateProfileImage = (
    userid: string,
    data: {
      /** @format uuid */
      OrganisationId?: string
      /** @format binary */
      ProfileImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/${userid}/profileimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name DeleteProfileImage
   * @request DELETE:/api/User/{userid}/profileimage
   * @secure
   */
  deleteProfileImage = (
    userid: string,
    query?: {
      /** @format uuid */
      organisationId?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/${userid}/profileimage`,
      method: 'DELETE',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name UpdateMyProfileImage
   * @request PUT:/api/User/profileimage/my
   * @secure
   */
  updateMyProfileImage = (
    data: {
      /** @format uuid */
      OrganisationId?: string
      /** @format binary */
      ProfileImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/profileimage/my`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags User
   * @name DeleteMyProfileImage
   * @request DELETE:/api/User/profileimage/my
   * @secure
   */
  deleteMyProfileImage = (
    query?: {
      /** @format uuid */
      organisationId?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/User/profileimage/my`,
      method: 'DELETE',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
}
