import { getOrganisationId } from 'utils/organisation'

const organisationId = getOrganisationId()

export const IMAGE_BASEURL = `https://dwlelearningstorage.blob.core.windows.net/${organisationId}-images/
`

export const CONTENT = 'CONTENT'
export const EMBED = 'EMBED'
export const IMAGE = 'IMAGE'
export const CONTENT_EMBED = 'CONTENTEMBED'
export const CONTENT_ONE_COLUMN = 'CONTENTONECOLUMN'
export const CONTENT_TWO_COLUMN = 'CONTENTTWOCOLUMN'
export const CONTENT_THREE_COLUMN = 'CONTENTTHREECOLUMN'
export const CONTENT_WITH_IMAGE = 'CONTENTWITHIMAGE'
export const MINIQUIZ = 'MINIQUIZ'
export const ASSESSMENT = 'ASSESSMENT'

// MiniQuiz section
export const RANDOMQUESTIONS = 'RANDOMQUESTIONS'
export const SETQUESTIONS = 'SETQUESTIONS'

// Assessment section
export const RANDOM = 'RANDOM'
export const STRUCTURED = 'STRUCTURED'
export const AUTO = 'AUTO'
export const ASSESSOR = 'ASSESSOR'

export const HUNDRED = '100'
export const FIFTY_FIFTY = '50/50'
export const THIRTYTHREE_THIRTYTHREE_THIRTYTHREE = '33/33/33'
export const THIRTYTHREE_SIXTYSIX = '33/66'
export const SIXTYSIX_THIRTYTHREE = '66/33'
