import * as Yup from 'yup'

export const hasSpecialCharacter = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/

export const hasCapitalLetter = /[A-Z]/

export const hasNumber = /\d/

export const passwordValidation = Yup.string()
  .min(8, 'Password should be a minimum of 8 characters')
  .max(20, 'Password should not be more than 20 characters')
  .matches(hasSpecialCharacter, 'Password must contain a special character')
  .matches(hasCapitalLetter, 'Password must contain a capital letter')
  .matches(hasNumber, 'Password must contain a number')
  .required('Password is required')
