import ClipLoader from 'react-spinners/ClipLoader'

export const LoaderSpinner = () => {
  return (
    <div className="width-[100%] height-[100vh] inline-flex">
      <ClipLoader
        color="#8d8d8d"
        cssOverride={{
          borderWidth: '15px',
          margin: 'auto',
          alignSelf: 'center',
          border: 'solid black',
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
        }}
        size={100}
        speedMultiplier={0.9}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}
