/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddContentSectionToSectionModel,
  AddMySectionAssessmentModel,
  AddMySectionNoteModel,
  ContentSectionModel,
  NoteModel,
  NotFoundExceptionModel,
  QuestionModel,
  SectionModel,
  UnauthorizedExceptionModel,
  UpdateContentSectionOrderModel,
  UpdateSectionModel,
  UpdateSectionProgressModel,
  UpdateSectionQuestionOrderModel,
  UserModuleSectionAssessmentModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Section<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Section
   * @name GetSection
   * @request GET:/api/Section/{sectionId}
   * @secure
   */
  getSection = (sectionId: string, params: RequestParams = {}) =>
    this.request<SectionModel, NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name UpdateSection
   * @request PUT:/api/Section/{sectionId}
   * @secure
   */
  updateSection = (
    sectionId: string,
    data: UpdateSectionModel,
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name DeleteSection
   * @request DELETE:/api/Section/{sectionId}
   * @secure
   */
  deleteSection = (sectionId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name GetMySectionAssessments
   * @request GET:/api/Section/{sectionId}/assessment/my
   * @secure
   */
  getMySectionAssessments = (
    sectionId: string,
    query?: {
      /** @format uuid */
      userCourseInstanceId?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModuleSectionAssessmentModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/assessment/my`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name StartMySectionAssessment
   * @request POST:/api/Section/{sectionId}/assessment/my/start
   * @secure
   */
  startMySectionAssessment = (
    sectionId: string,
    data: AddMySectionAssessmentModel,
    params: RequestParams = {},
  ) =>
    this.request<UserModuleSectionAssessmentModel, NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/assessment/my/start`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name GetSectionContentSections
   * @request GET:/api/Section/{sectionId}/contentsections
   * @secure
   */
  getSectionContentSections = (sectionId: string, params: RequestParams = {}) =>
    this.request<ContentSectionModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/contentsections`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name AddContentSectionToSection
   * @request POST:/api/Section/{sectionId}/contentsections
   * @secure
   */
  addContentSectionToSection = (
    sectionId: string,
    data: AddContentSectionToSectionModel,
    params: RequestParams = {},
  ) =>
    this.request<
      ContentSectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/contentsections`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name DeleteContentSectionFromSection
   * @request DELETE:/api/Section/{sectionId}/contentsections/{contentSectionId}
   * @secure
   */
  deleteContentSectionFromSection = (
    sectionId: string,
    contentSectionId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/contentsections/${contentSectionId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name UpdateContentSectionOrder
   * @request PUT:/api/Section/{sectionId}/contentsections/order
   * @secure
   */
  updateContentSectionOrder = (
    sectionId: string,
    data: UpdateContentSectionOrderModel,
    params: RequestParams = {},
  ) =>
    this.request<
      ContentSectionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/contentsections/order`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name UpdateSectionContentImage
   * @request PUT:/api/Section/{sectionId}/images/contentimage
   * @secure
   */
  updateSectionContentImage = (
    sectionId: string,
    data: {
      /** @format binary */
      contentImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/images/contentimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name DeleteSectionContentImage
   * @request DELETE:/api/Section/{sectionId}/images/contentimage
   * @secure
   */
  deleteSectionContentImage = (sectionId: string, params: RequestParams = {}) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/images/contentimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name UpdateMySectionProgress
   * @request PUT:/api/Section/{sectionId}/myprogress
   * @secure
   */
  updateMySectionProgress = (
    sectionId: string,
    data: UpdateSectionProgressModel,
    params: RequestParams = {},
  ) =>
    this.request<void, NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/myprogress`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name GetMySectionNotes
   * @request GET:/api/Section/{sectionId}/usernotes
   * @secure
   */
  getMySectionNotes = (
    sectionId: string,
    query?: {
      /** @format uuid */
      userCourseInstanceId?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<NoteModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/usernotes`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name AddMySectionNote
   * @request POST:/api/Section/{sectionId}/usernotes
   * @secure
   */
  addMySectionNote = (
    sectionId: string,
    data: AddMySectionNoteModel,
    params: RequestParams = {},
  ) =>
    this.request<NoteModel, NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/usernotes`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name GetSectionQuestions
   * @request GET:/api/Section/{sectionId}/questions
   * @secure
   */
  getSectionQuestions = (sectionId: string, params: RequestParams = {}) =>
    this.request<QuestionModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/questions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name GetSectionRandomQuestions
   * @request GET:/api/Section/{sectionId}/questions/forassessment
   * @secure
   */
  getSectionRandomQuestions = (sectionId: string, params: RequestParams = {}) =>
    this.request<QuestionModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/questions/forassessment`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name AddQuestionToSection
   * @request PUT:/api/Section/{sectionId}/questions/{questionId}
   * @secure
   */
  addQuestionToSection = (
    sectionId: string,
    questionId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      QuestionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/questions/${questionId}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name DeleteQuestionFromSection
   * @request DELETE:/api/Section/{sectionId}/questions/{questionId}
   * @secure
   */
  deleteQuestionFromSection = (
    sectionId: string,
    questionId: string,
    params: RequestParams = {},
  ) =>
    this.request<QuestionModel[], NotFoundExceptionModel>({
      path: `/api/Section/${sectionId}/questions/${questionId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Section
   * @name UpdateSectionQuestionOrder
   * @request PUT:/api/Section/{sectionId}/questions/order
   * @secure
   */
  updateSectionQuestionOrder = (
    sectionId: string,
    data: UpdateSectionQuestionOrderModel,
    params: RequestParams = {},
  ) =>
    this.request<
      QuestionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Section/${sectionId}/questions/order`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
