/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotFoundExceptionModel,
  QuestionModel,
  ResourceModel,
  UnauthorizedExceptionModel,
  UpdateResourceModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Resource<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Resource
   * @name GetResource
   * @request GET:/api/Resource/{resourceId}
   * @secure
   */
  getResource = (resourceId: string, params: RequestParams = {}) =>
    this.request<ResourceModel, NotFoundExceptionModel>({
      path: `/api/Resource/${resourceId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResource
   * @request PUT:/api/Resource/{resourceId}
   * @secure
   */
  updateResource = (
    resourceId: string,
    data: UpdateResourceModel,
    params: RequestParams = {},
  ) =>
    this.request<
      QuestionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Resource/${resourceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Resource
   * @name DeleteResource
   * @request DELETE:/api/Resource/{resourceId}
   * @secure
   */
  deleteResource = (resourceId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Resource/${resourceId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Resource
   * @name GetResourceFile
   * @request GET:/api/Resource/{resourceId}/file
   * @secure
   */
  getResourceFile = (resourceId: string, params: RequestParams = {}) =>
    this.request<void, NotFoundExceptionModel>({
      path: `/api/Resource/${resourceId}/file`,
      method: 'GET',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceFile
   * @request PUT:/api/Resource/{resourceId}/file
   * @secure
   */
  updateResourceFile = (
    resourceId: string,
    data: {
      /** @format binary */
      File?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ResourceModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Resource/${resourceId}/file`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
}
