import ClipLoader from 'react-spinners/ClipLoader'

interface ButtonLoaderSpinnerProps {
  spinnerColor: string
  spinnerSize: number
  loadingState: boolean
}

const ButtonLoaderSpinner = ({
  spinnerColor,
  spinnerSize,
  loadingState,
}: ButtonLoaderSpinnerProps) => {
  return (
    <ClipLoader
      color={spinnerColor}
      cssOverride={{
        borderWidth: '2px',
        margin: 'auto',
        alignSelf: 'center',
        border: 'solid black',
        marginLeft: '5px',
      }}
      size={spinnerSize}
      loading={loadingState}
      speedMultiplier={0.9}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  )
}

export default ButtonLoaderSpinner
