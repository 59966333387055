/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddMessageTemplateToOrganisationModel,
  AddOrganisationModel,
  AddPolicyDocumentToOrganisationModel,
  AddTopicOrganisationModel,
  BrandingModel,
  CourseInstanceModel,
  CourseModel,
  MessageTemplateModel,
  NotFoundExceptionModel,
  OrganisationModel,
  OrganisationRoleModel,
  OrganisationSettingModel,
  OrganisationTestimonialModel,
  PolicyDocumentModel,
  SiteDomainModel,
  StatDataModel,
  TopicModel,
  UpdateOrganisationModel,
  UpdateOrganisationSettingsModel,
  UpdateStripeDataForOrganisationModel,
  UpdateUserOrganisationRolesModel,
  UserCourseInstanceModel,
  UserModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Organisation<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Organisation
   * @name AddOrganisation
   * @request POST:/api/Organisation/add
   * @secure
   */
  addOrganisation = (data: AddOrganisationModel, params: RequestParams = {}) =>
    this.request<OrganisationModel, ValidationExceptionModel>({
      path: `/api/Organisation/add`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name Branding
   * @request GET:/api/Organisation/{domain}/branding
   * @secure
   */
  branding = (domain: string, params: RequestParams = {}) =>
    this.request<BrandingModel, NotFoundExceptionModel>({
      path: `/api/Organisation/${domain}/branding`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name OrganisationCourses
   * @request GET:/api/Organisation/{organisationId}/courses
   * @secure
   */
  organisationCourses = (organisationId: string, params: RequestParams = {}) =>
    this.request<CourseModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddNewCourseToOrganisation
   * @request POST:/api/Organisation/{organisationId}/courses
   * @secure
   */
  addNewCourseToOrganisation = (
    organisationId: string,
    data: {
      Name?: string
      SubTitle?: string
      Description?: string
      PreInfo?: string
      CourseContent?: string
      Duration?: string
      RunsOver?: string
      /** @format uuid */
      TopicId?: string
      Tags?: string
      /** @format binary */
      CourseImage?: File
      CourseStatus?: string
      CourseStructure?: string
      CourseCompletionTitle?: string
      CourseCompletionDescription?: string
      /** @format binary */
      CourseCompletionImage?: File
      WelcomeEmailOptional?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<
      CourseModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/courses`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name CopyCourse
   * @request POST:/api/Organisation/{organisationId}/courses/{courseId}/copy
   * @secure
   */
  copyCourse = (
    organisationId: string,
    courseId: string,
    params: RequestParams = {},
  ) =>
    this.request<CourseModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses/${courseId}/copy`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetCourseInstancesByOrganisation
   * @request GET:/api/Organisation/{organisationId}/courses/instances
   * @secure
   */
  getCourseInstancesByOrganisation = (
    organisationId: string,
    params: RequestParams = {},
  ) =>
    this.request<CourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses/instances`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetActivePublicCourseInstancesByOrganisation
   * @request GET:/api/Organisation/{organisationId}/courses/instances/activepublic
   * @secure
   */
  getActivePublicCourseInstancesByOrganisation = (
    organisationId: string,
    params: RequestParams = {},
  ) =>
    this.request<CourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses/instances/activepublic`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetMyCoursesByOrganisation
   * @request GET:/api/Organisation/{organisationId}/courses/instances/mycourses
   * @secure
   */
  getMyCoursesByOrganisation = (
    organisationId: string,
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses/instances/mycourses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetUserCoursesByOrganisation
   * @request GET:/api/Organisation/{organisationId}/courses/instances/users/{userId}
   * @secure
   */
  getUserCoursesByOrganisation = (
    organisationId: string,
    userId: string,
    params: RequestParams = {},
  ) =>
    this.request<UserCourseInstanceModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/courses/instances/users/${userId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetOrganisationById
   * @request GET:/api/Organisation/{organisationId}
   * @secure
   */
  getOrganisationById = (organisationId: string, params: RequestParams = {}) =>
    this.request<OrganisationModel, NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateOrganisation
   * @request PUT:/api/Organisation/{organisationId}
   * @secure
   */
  updateOrganisation = (
    organisationId: string,
    data: UpdateOrganisationModel,
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateStripeData
   * @request PUT:/api/Organisation/{organisationId}/payments/stripe
   * @secure
   */
  updateStripeData = (
    organisationId: string,
    data: UpdateStripeDataForOrganisationModel,
    params: RequestParams = {},
  ) =>
    this.request<void, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/payments/stripe`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name OrganisationsByUser
   * @request GET:/api/Organisation/OrganisationsByUser
   * @secure
   */
  organisationsByUser = (params: RequestParams = {}) =>
    this.request<OrganisationModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/OrganisationsByUser`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateBannerImage
   * @request PUT:/api/Organisation/{organisationId}/images/bannerimage
   * @secure
   */
  updateBannerImage = (
    organisationId: string,
    data: {
      /** @format binary */
      bannerImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationSettingModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/bannerimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name DeleteBannerImage
   * @request DELETE:/api/Organisation/{organisationId}/images/bannerimage
   * @secure
   */
  deleteBannerImage = (organisationId: string, params: RequestParams = {}) =>
    this.request<
      OrganisationSettingModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/bannerimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateCompanyLogo
   * @request PUT:/api/Organisation/{organisationId}/images/companylogo
   * @secure
   */
  updateCompanyLogo = (
    organisationId: string,
    data: {
      /** @format binary */
      companyLogo?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/companylogo`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name DeleteCompanyLogo
   * @request DELETE:/api/Organisation/{organisationId}/images/companylogo
   * @secure
   */
  deleteCompanyLogo = (organisationId: string, params: RequestParams = {}) =>
    this.request<
      OrganisationModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/companylogo`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateEmailLogo
   * @request PUT:/api/Organisation/{organisationId}/images/emaillogo
   * @secure
   */
  updateEmailLogo = (
    organisationId: string,
    data: {
      /** @format binary */
      emailLogo?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/emaillogo`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name DeleteEmailLogo
   * @request DELETE:/api/Organisation/{organisationId}/images/emaillogo
   * @secure
   */
  deleteEmailLogo = (organisationId: string, params: RequestParams = {}) =>
    this.request<
      OrganisationModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/images/emaillogo`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UploadImage
   * @request POST:/api/Organisation/{organisationId}/images/upload
   * @secure
   */
  uploadImage = (
    organisationId: string,
    data: {
      /** @format binary */
      image?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<string, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/images/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetMessageTemplates
   * @request GET:/api/Organisation/{organisationId}/messagetemplates
   * @secure
   */
  getMessageTemplates = (organisationId: string, params: RequestParams = {}) =>
    this.request<MessageTemplateModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/messagetemplates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddMessageTemplateToOrganisation
   * @request POST:/api/Organisation/{organisationId}/messagetemplates
   * @secure
   */
  addMessageTemplateToOrganisation = (
    organisationId: string,
    data: AddMessageTemplateToOrganisationModel,
    params: RequestParams = {},
  ) =>
    this.request<
      MessageTemplateModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/messagetemplates`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetPolicyDocuments
   * @request GET:/api/Organisation/{organisationId}/policydocuments
   * @secure
   */
  getPolicyDocuments = (organisationId: string, params: RequestParams = {}) =>
    this.request<PolicyDocumentModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/policydocuments`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddPolicyDocumentsToOrganisation
   * @request POST:/api/Organisation/{organisationId}/policydocuments
   * @secure
   */
  addPolicyDocumentsToOrganisation = (
    organisationId: string,
    data: AddPolicyDocumentToOrganisationModel,
    params: RequestParams = {},
  ) =>
    this.request<
      PolicyDocumentModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/policydocuments`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetOrganisationRoles
   * @request GET:/api/Organisation/{organisationId}/roles
   * @secure
   */
  getOrganisationRoles = (organisationId: string, params: RequestParams = {}) =>
    this.request<OrganisationRoleModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/roles`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetSettings
   * @request GET:/api/Organisation/{organisationId}/settings
   * @secure
   */
  getSettings = (organisationId: string, params: RequestParams = {}) =>
    this.request<OrganisationSettingModel, NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/settings`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateOrganisationSettings
   * @request PUT:/api/Organisation/{organisationId}/settings
   * @secure
   */
  updateOrganisationSettings = (
    organisationId: string,
    data: UpdateOrganisationSettingsModel,
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationSettingModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/settings`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetSiteDomains
   * @request GET:/api/Organisation/{organisationId}/sitedomains
   * @secure
   */
  getSiteDomains = (organisationId: string, params: RequestParams = {}) =>
    this.request<SiteDomainModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/sitedomains`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetStatsDashboard
   * @request GET:/api/Organisation/{organisationId}/stats
   * @secure
   */
  getStatsDashboard = (
    organisationId: string,
    query?: {
      statType?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<StatDataModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/stats`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetTestimonials
   * @request GET:/api/Organisation/{organisationId}/testimonials
   * @secure
   */
  getTestimonials = (organisationId: string, params: RequestParams = {}) =>
    this.request<OrganisationTestimonialModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/testimonials`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddTestimonialToOrganisation
   * @request POST:/api/Organisation/{organisationId}/testimonials
   * @secure
   */
  addTestimonialToOrganisation = (
    organisationId: string,
    data: {
      Testimonial?: string
      Author?: string
      /** @format binary */
      Image?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      OrganisationTestimonialModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Organisation/${organisationId}/testimonials`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name GetTopics
   * @request GET:/api/Organisation/{organisationId}/topics
   * @secure
   */
  getTopics = (organisationId: string, params: RequestParams = {}) =>
    this.request<TopicModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/topics`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddTopicToOrganisation
   * @request POST:/api/Organisation/{organisationId}/topics
   * @secure
   */
  addTopicToOrganisation = (
    organisationId: string,
    data: AddTopicOrganisationModel,
    params: RequestParams = {},
  ) =>
    this.request<TopicModel, ValidationExceptionModel | NotFoundExceptionModel>(
      {
        path: `/api/Organisation/${organisationId}/topics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      },
    )
  /**
   * No description
   *
   * @tags Organisation
   * @name OrganisationUsers
   * @request GET:/api/Organisation/{organisationId}/users
   * @secure
   */
  organisationUsers = (organisationId: string, params: RequestParams = {}) =>
    this.request<UserModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddNewUserToOrganisation
   * @request POST:/api/Organisation/{organisationId}/users
   * @secure
   */
  addNewUserToOrganisation = (
    organisationId: string,
    data: {
      UserName?: string
      Email?: string
      Firstname?: string
      Lastname?: string
      Department?: string
      Bio?: string
      /** @format binary */
      ProfileImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name AddExistingUserToOrganisation
   * @request PUT:/api/Organisation/{organisationId}/users/{userId}/assign
   * @secure
   */
  addExistingUserToOrganisation = (
    organisationId: string,
    userId: string,
    params: RequestParams = {},
  ) =>
    this.request<UserModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users/${userId}/assign`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name OrganisationUserRoles
   * @request GET:/api/Organisation/{organisationId}/users/{userId}/roles
   * @secure
   */
  organisationUserRoles = (
    organisationId: string,
    userId: string,
    params: RequestParams = {},
  ) =>
    this.request<OrganisationRoleModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users/${userId}/roles`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name UpdateOrganisationUserRoles
   * @request PUT:/api/Organisation/{organisationId}/users/{userId}/roles
   * @secure
   */
  updateOrganisationUserRoles = (
    organisationId: string,
    userId: string,
    data: UpdateUserOrganisationRolesModel,
    params: RequestParams = {},
  ) =>
    this.request<OrganisationRoleModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users/${userId}/roles`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Organisation
   * @name OrganisationUsersWithRole
   * @request GET:/api/Organisation/{organisationId}/users/withroles
   * @secure
   */
  organisationUsersWithRole = (
    organisationId: string,
    query?: {
      specificrole?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel[], NotFoundExceptionModel>({
      path: `/api/Organisation/${organisationId}/users/withroles`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
}
