/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CompletionModel,
  CourseModel,
  ModuleModel,
  NotFoundExceptionModel,
  ResourceModel,
  SectionModel,
  UnauthorizedExceptionModel,
  UpdateModuleCompleteModel,
  UpdateModuleModel,
  UpdateModuleSectionOrderModel,
  UpdateMyModuleCompleteModel,
  UserModuleDataModel,
  UserModuleFeedbackModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Module<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Module
   * @name GetModule
   * @request GET:/api/Module/{moduleId}
   * @secure
   */
  getModule = (moduleId: string, params: RequestParams = {}) =>
    this.request<CourseModel, NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name UpdateModule
   * @request PUT:/api/Module/{moduleId}
   * @secure
   */
  updateModule = (
    moduleId: string,
    data: UpdateModuleModel,
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name DeleteModule
   * @request DELETE:/api/Module/{moduleId}
   * @secure
   */
  deleteModule = (moduleId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name GetModuleData
   * @request GET:/api/Module/{moduleId}/data
   * @secure
   */
  getModuleData = (moduleId: string, params: RequestParams = {}) =>
    this.request<CourseModel, NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/data`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name CompleteUsersModule
   * @request POST:/api/Module/{moduleId}/complete
   * @secure
   */
  completeUsersModule = (
    moduleId: string,
    data: UpdateModuleCompleteModel,
    params: RequestParams = {},
  ) =>
    this.request<SectionModel[], NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/complete`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name FeedbackUsersModule
   * @request POST:/api/Module/{moduleId}/feedback
   * @secure
   */
  feedbackUsersModule = (
    moduleId: string,
    data: UserModuleFeedbackModel,
    params: RequestParams = {},
  ) =>
    this.request<UserModuleDataModel, NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/feedback`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name UpdateModuleCompletionImage
   * @request PUT:/api/Module/{moduleId}/images/modulecompletionimage
   * @secure
   */
  updateModuleCompletionImage = (
    moduleId: string,
    data: {
      /** @format binary */
      moduleCompletionImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/images/modulecompletionimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name DeleteModuleCompletionImage
   * @request DELETE:/api/Module/{moduleId}/images/modulecompletionimage
   * @secure
   */
  deleteModuleCompletionImage = (
    moduleId: string,
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/images/modulecompletionimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name UpdateModuleImage
   * @request PUT:/api/Module/{moduleId}/images/moduleimage
   * @secure
   */
  updateModuleImage = (
    moduleId: string,
    data: {
      /** @format binary */
      moduleImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/images/moduleimage`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name DeleteModuleImage
   * @request DELETE:/api/Module/{moduleId}/images/moduleimage
   * @secure
   */
  deleteModuleImage = (moduleId: string, params: RequestParams = {}) =>
    this.request<
      ModuleModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/images/moduleimage`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name GetMyModuleData
   * @request GET:/api/Module/{moduleId}/my
   * @secure
   */
  getMyModuleData = (
    moduleId: string,
    query?: {
      /** @format uuid */
      userCourseInstanceId?: string
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModuleDataModel, NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/my`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name CompleteMyModule
   * @request POST:/api/Module/{moduleId}/my/complete
   * @secure
   */
  completeMyModule = (
    moduleId: string,
    data: UpdateMyModuleCompleteModel,
    params: RequestParams = {},
  ) =>
    this.request<CompletionModel, NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/my/complete`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name GetModuleResources
   * @request GET:/api/Module/{moduleId}/resources
   * @secure
   */
  getModuleResources = (moduleId: string, params: RequestParams = {}) =>
    this.request<ResourceModel[], NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/resources`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name AddResourceToModule
   * @request PUT:/api/Module/{moduleId}/resources/{resourceId}
   * @secure
   */
  addResourceToModule = (
    moduleId: string,
    resourceId: string,
    params: RequestParams = {},
  ) =>
    this.request<ResourceModel[], NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/resources/${resourceId}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name RemoveResourceFromModule
   * @request DELETE:/api/Module/{moduleId}/resources/{resourceId}
   * @secure
   */
  removeResourceFromModule = (
    moduleId: string,
    resourceId: string,
    params: RequestParams = {},
  ) =>
    this.request<ResourceModel[], NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/resources/${resourceId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name GetSections
   * @request GET:/api/Module/{moduleId}/sections
   * @secure
   */
  getSections = (moduleId: string, params: RequestParams = {}) =>
    this.request<SectionModel[], NotFoundExceptionModel>({
      path: `/api/Module/${moduleId}/sections`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name AddNewSectionToModule
   * @request POST:/api/Module/{moduleId}/sections
   * @secure
   */
  addNewSectionToModule = (
    moduleId: string,
    data: {
      Name?: string
      Description?: string
      Tags?: string
      IsInMenu?: boolean
      SectionType?: string
      SectionTemplate?: string
      IsRequiredToBeCompleted?: boolean
      Title?: string
      /** @format binary */
      ContentImage?: File
      ContentArea1?: string
      ContentArea2?: string
      ContentArea3?: string
      /** @format int32 */
      MiniQuizNumQuestionsToShow?: number
      /** @format int32 */
      AssessmentNumQuestionsToShow?: number
      AssessmentMarkProcess?: string
      AssessmentQuestionOrder?: string
      /** @format int32 */
      AssessmentPassRate?: number
      AssessmentIsTimed?: boolean
      /** @format int32 */
      AssessmentTimePerQuestionSeconds?: number
      /** @format int32 */
      AssessmentNumberOfAttempts?: number
    },
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/sections`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Module
   * @name UpdateModuleSectionOrder
   * @request PUT:/api/Module/{moduleId}/sections/order
   * @secure
   */
  updateModuleSectionOrder = (
    moduleId: string,
    data: UpdateModuleSectionOrderModel,
    params: RequestParams = {},
  ) =>
    this.request<
      SectionModel[],
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/Module/${moduleId}/sections/order`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
