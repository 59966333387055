/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotFoundExceptionModel,
  PolicyDocumentModel,
  ResourceModel,
  UnauthorizedExceptionModel,
  UpdatePolicyDocumentModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class PolicyDocument<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PolicyDocument
   * @name GetPolicy
   * @request GET:/api/PolicyDocument/{policyId}
   * @secure
   */
  getPolicy = (policyId: string, params: RequestParams = {}) =>
    this.request<ResourceModel, NotFoundExceptionModel>({
      path: `/api/PolicyDocument/${policyId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags PolicyDocument
   * @name UpdatePolicyDocument
   * @request PUT:/api/PolicyDocument/{policyId}
   * @secure
   */
  updatePolicyDocument = (
    policyId: string,
    data: UpdatePolicyDocumentModel,
    params: RequestParams = {},
  ) =>
    this.request<
      PolicyDocumentModel,
      ValidationExceptionModel | NotFoundExceptionModel
    >({
      path: `/api/PolicyDocument/${policyId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags PolicyDocument
   * @name DeletePolicyDocument
   * @request DELETE:/api/PolicyDocument/{policyId}
   * @secure
   */
  deletePolicyDocument = (policyId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/PolicyDocument/${policyId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}
