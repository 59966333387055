/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CheckUserExistsCommand,
  CheckUserExistsModel,
  CompleteSetupCommand,
  LoginResponseModel,
  LoginUserModel,
  NotFoundExceptionModel,
  RefreshTokenCommand,
  RequestPasswordResetModel,
  ResetUserPasswordCommand,
  UnauthorizedExceptionModel,
  UserModel,
  ValidationExceptionModel,
  VerifyEmailCommand,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Auth<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Auth
   * @name CompleteSetup
   * @request POST:/api/Auth/completesetup
   * @secure
   */
  completeSetup = (data: CompleteSetupCommand, params: RequestParams = {}) =>
    this.request<void, ValidationExceptionModel | UnauthorizedExceptionModel>({
      path: `/api/Auth/completesetup`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name CheckEmailExists
   * @request POST:/api/Auth/exists
   * @secure
   */
  checkEmailExists = (
    data: CheckUserExistsCommand,
    params: RequestParams = {},
  ) =>
    this.request<CheckUserExistsModel, ValidationExceptionModel>({
      path: `/api/Auth/exists`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name LoginUser
   * @request POST:/api/Auth/login
   * @secure
   */
  loginUser = (data: LoginUserModel, params: RequestParams = {}) =>
    this.request<
      LoginResponseModel,
      ValidationExceptionModel | UnauthorizedExceptionModel
    >({
      path: `/api/Auth/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name Profile
   * @request GET:/api/Auth/profile
   * @secure
   */
  profile = (params: RequestParams = {}) =>
    this.request<UserModel, NotFoundExceptionModel>({
      path: `/api/Auth/profile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name RegisterUser
   * @request POST:/api/Auth/register
   * @secure
   */
  registerUser = (
    data: {
      UserName?: string
      Email?: string
      Firstname?: string
      Lastname?: string
      Department?: string
      Password?: string
      /** @format uuid */
      OrganisationId?: string
      BaseSiteUrl?: string
      /** @format binary */
      ProfileImage?: File
    },
    params: RequestParams = {},
  ) =>
    this.request<UserModel, ValidationExceptionModel>({
      path: `/api/Auth/register`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name AuthTokenRefreshCreate
   * @request POST:/api/Auth/token/refresh
   * @secure
   */
  authTokenRefreshCreate = (
    data: RefreshTokenCommand,
    params: RequestParams = {},
  ) =>
    this.request<
      LoginResponseModel,
      ValidationExceptionModel | UnauthorizedExceptionModel
    >({
      path: `/api/Auth/token/refresh`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name ResetUserPassword
   * @request POST:/api/Auth/reset
   * @secure
   */
  resetUserPassword = (
    data: ResetUserPasswordCommand,
    params: RequestParams = {},
  ) =>
    this.request<void, ValidationExceptionModel | UnauthorizedExceptionModel>({
      path: `/api/Auth/reset`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name RequestPasswordReset
   * @request POST:/api/Auth/reset/request
   * @secure
   */
  requestPasswordReset = (
    data: RequestPasswordResetModel,
    params: RequestParams = {},
  ) =>
    this.request<void, ValidationExceptionModel>({
      path: `/api/Auth/reset/request`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  /**
   * No description
   *
   * @tags Auth
   * @name VerifyEmail
   * @request POST:/api/Auth/verifyemail
   * @secure
   */
  verifyEmail = (data: VerifyEmailCommand, params: RequestParams = {}) =>
    this.request<void, ValidationExceptionModel | UnauthorizedExceptionModel>({
      path: `/api/Auth/verifyemail`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
}
